.list-row {
    width: 100%;
    border-top: 1px solid #83d0e4;
    padding: 0.5rem 0.75rem;
}

.item-quantity-and-options {
    display: flex;
    flex-direction: column;
    padding: 0;
    flex: 0.3;
    justify-content: center;
    text-wrap: none;
    margin-left: auto;
    align-items: flex-end;
}

.item-quantity {
    display: flex;
    margin-left: auto;
    text-align: end;
    flex-wrap: nowrap;
}


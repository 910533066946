.container {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 100vh;
    padding-right: 0;
    padding-left: 0;
    background-color: #83d0e4;
}

.page-body {
    display: flex;
    flex-direction: column;
    flex: 1;
    border-radius: 0;
    border-right-width: 0;
    border-left-width: 0;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}

.logo {
    object-fit: contain;
    max-height: 8rem;
    max-width: 70%;
    margin: 2rem 0;
}

.content {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 65rem;
    max-width: 100%;
    min-height: 100%;
    border-radius: 0.5rem;
}

.card-body {
    display: flex;
    min-height: 100%;

    @media screen and (max-width: 991px) {
        padding-left: 0;
        padding-right: 0;
    }
}

.container {
    @media screen and (max-width: 991px) {
        padding: 0;
        font-size: 0.9rem;
    }
}

.image-print {
}

.item-quantity-print {
    @media print {
        margin-top: 0 !important;
    }
}

.title-print {
    display: none;
    visibility: hidden;

    @media print {
        display: inline !important;
        visibility: visible;
    }
}

.logo-print {
    display: none;

    @media print {
        display: block !important;
        visibility: visible;
        object-fit: contain;
        max-height: 8rem;
        max-width: 70%;
        margin: 0 auto 4rem auto;
    }
}

.accordion {
    border-radius: 0;
    width: 100%;
}

.accordion-item {
    display: flex;
    flex-direction: column;
    padding: 5px;
    width: 100%;
}

.category-item {
    width: 100%;
    border-top: 1px solid #83d0e4;
    padding: 0.5rem 1rem 0.5rem 1.25rem;
    align-items: center;
}

.category-item-inner {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
}

.sidebar-burger-icon {
    display: flex;
    margin: 1rem;
    @media ('min-width: 1024px') {
        display: none;
    }
}

.logo {
    max-height: 5rem;
    width: 100%;
    margin: 0 1.5rem 0 0;
    @media screen and (max-width: 750px) {
        margin-bottom: 1.5rem;
    }
}

.footer {
    display: flex;
    width: 100%;
    font-size: 12px;
    justify-content: flex-end;
    padding: 0.5rem;
    z-index: 100;
}

.footer-text {
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 1rem;
}

.logo {
    object-fit: contain;
    max-height: 1.5rem;
    width: 100%;
    margin: 0 1.5rem 0 0;
}

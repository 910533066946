body {
    margin: 0;
    font-family:
        -apple-system,
        BlinkMacSystemFont,
        "Segoe UI",
        "Roboto",
        "Oxygen",
        "Ubuntu",
        "Cantarell",
        "Fira Sans",
        "Droid Sans",
        "Helvetica Neue",
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    padding-left: 0 !important;
    padding-right: 0 !important;

    .table-bordered {
        background-color: #ffffff;
    }

    .modal-buttons {
        margin-left: auto;
        margin-right: 10px;
        border-radius: 10px;
        min-width: 80px;
    }

    .modal-close-button {
        margin-right: 5px;
    }

    .accordion {
        --bs-accordion-color: #000;
        // --bs-accordion-bg: #fff;
        --bs-accordion-bg: "";
        --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
        // --bs-accordion-border-color: #aaa;
        --bs-accordion-border-color: #83d0e4;
        --bs-accordion-border-width: 1px;
        --bs-accordion-border-radius: 0.375rem;
        --bs-accordion-inner-border-radius: calc(0.375rem - 1px);
        --bs-accordion-btn-padding-x: 1.25rem;
        --bs-accordion-btn-padding-y: 10px;
        --bs-accordion-btn-color: var(--bs-body-color);
        --bs-accordion-btn-bg: var(--bs-accordion-bg);
        --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='var%28--bs-body-color%29'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
        --bs-accordion-btn-icon-width: 1.25rem;
        --bs-accordion-btn-icon-transform: rotate(-180deg);
        --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
        --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
        --bs-accordion-btn-focus-border-color: #86b7fe;
        --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
        --bs-accordion-body-padding-x: 3rem;

        @media screen and (max-width: 991px) {
            --bs-accordion-btn-padding-x: 0.75rem;
            --bs-accordion-body-padding-x: 1.5rem;
        }

        --bs-accordion-body-padding-y: 1rem;
        // --bs-accordion-active-color: #0c63e4;
        --bs-accordion-active-color: black;
        --bs-accordion-active-bg: #e7f1ff;
    }

    .accordion-button {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
        font-size: 1rem;
        color: var(--bs-accordion-btn-color);
        text-align: left;
        background-color: var(--bs-accordion-btn-bg);
        border: 0;
        border-radius: 0;
        overflow-anchor: none;
        transition: var(--bs-accordion-transition);
    }
    @media (prefers-reduced-motion: reduce) {
        .accordion-button {
            transition: none;
        }
    }
    .accordion-button:not(.collapsed) {
        color: var(--bs-accordion-active-color);
        // background-color: var(--bs-accordion-active-bg);
    }
    .accordion-button:not(.collapsed)::after {
        background-image: var(--bs-accordion-btn-active-icon);
        transform: var(--bs-accordion-btn-icon-transform);
    }
    .accordion-button::after {
        flex-shrink: 0;
        width: var(--bs-accordion-btn-icon-width);
        height: var(--bs-accordion-btn-icon-width);
        margin-left: auto;
        content: "";
        background-image: var(--bs-accordion-btn-icon);
        background-repeat: no-repeat;
        background-size: var(--bs-accordion-btn-icon-width);
        transition: var(--bs-accordion-btn-icon-transition);
    }
    @media (prefers-reduced-motion: reduce) {
        .accordion-button::after {
            transition: none;
        }
    }
    .accordion-button:hover {
        z-index: 2;
    }
    .accordion-button:focus {
        z-index: 3;
        border-color: var(--bs-accordion-btn-focus-border-color);
        outline: 0;
        // box-shadow: var(--bs-accordion-btn-focus-box-shadow);
    }

    .accordion-header {
        margin-bottom: 0;
    }

    .accordion-item {
        color: var(--bs-accordion-color);
        background-color: var(--bs-accordion-bg);
        border-top: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
    }
    .accordion-item:first-of-type {
        border-top-left-radius: var(--bs-accordion-border-radius);
        border-top-right-radius: var(--bs-accordion-border-radius);
    }
    .accordion-item:first-of-type .accordion-button {
        border-top-left-radius: var(--bs-accordion-inner-border-radius);
        border-top-right-radius: var(--bs-accordion-inner-border-radius);
    }
    .accordion-item:not(:first-of-type) {
        border-top: 0;
    }
    .accordion-item:last-of-type {
        border-bottom-right-radius: var(--bs-accordion-border-radius);
        border-bottom-left-radius: var(--bs-accordion-border-radius);
    }
    .accordion-item:last-of-type .accordion-button.collapsed {
        border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
        border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
    }
    .accordion-item:last-of-type .accordion-collapse {
        border-bottom-right-radius: var(--bs-accordion-border-radius);
        border-bottom-left-radius: var(--bs-accordion-border-radius);
    }

    .accordion-body {
        padding: 0 0 0 var(--bs-accordion-body-padding-x);
    }

    .accordion-flush .accordion-collapse {
        border-width: 0;
    }
    .accordion-flush .accordion-item {
        border-right: 0;
        border-left: 0;
        border-radius: 0;
    }

    .accordion-flush .accordion-item:last-child {
        border-bottom: 0;
    }
    .accordion-flush .accordion-item .accordion-button {
        border-radius: 0;
    }
}

code {
    font-family:
        source-code-pro,
        Menlo,
        Monaco,
        Consolas,
        "Courier New",
        monospace;
}
// Fixes the massive scrollbar flickering
.row{
    margin-right:0;
    margin-left:0;
}

// Make card container match body color
.card {
    background-color: #f9f9f9;
}

.btn-primary {
    background-color: #2256a5;
}

// general util
.center {
    justify-content: center;
    align-items: center;
}

.fill-row {
    display: flex;
    flex-direction: row;
    flex: 1;
}

.fill-col {
    display: flex;
    flex-direction: column;
    flex: 1;
}

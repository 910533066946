$color-public-primary: #83d0e4;
$color-private-primary: #06a763;
$color-logo-green: #06a763;
$color-logo-blue: #2256a5;
$color-gray: #6c757d;
$color-gray-400: #ced4da;
$color-background: #fff;
$color-warning: #f8c839;
$color-success: #5fb173;
$color-danger: #c45b65;
$color-lighter-green: #defade;
$color-dark-background: #222;

$theme-colors: (
    "primary":    $color-logo-blue,
    "secondary":  #6c757d,
    "success":    $color-logo-green,
    "info":       $color-logo-blue,
    "warning":    $color-warning,
    "danger":     $color-danger,
    "light":      $color-lighter-green,
    "dark":       #212529
);

@import '~bootstrap/scss/bootstrap.scss';

$theme-colors: (
    "primary":    $color-logo-blue,
    "secondary":  #6c757d,
    "success":    $color-logo-green,
    "info":       $color-logo-blue,
    "warning":    $color-warning,
    "danger":     $color-danger,
    "light":      $color-lighter-green,
    "dark":       #212529
);
